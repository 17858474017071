import React, { useEffect, useState } from 'react'
import { withTrans } from '../../i18n/withTrans'
import { useTranslation } from 'react-i18next'
import LocalizedLink from '../LocalizedLink'
import MetaTags from '../MetaTags'
import Navigation from '../../components/Navigation'
import Footer from '../../components/Footer'
import axios from 'axios'

import './quiz-result.scss'
import banner_desktop from '../../images/socmed/banner-socmed-desktop.webp'

const HairQuizResult = ({ pageContext, location }) => {
    const [hairConcern, setHairConcern] = useState('')
    const [hairType, setHairType] = useState('')
    const [products, setProducts] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const params = new URLSearchParams(location.search)
                const answer1 = params.get('answer1')
                const answer2 = params.get('answer2')
                const answer3 = params.get('answer3')
                const answer4 = params.get('answer4')
                const answer5 = params.get('answer5')
                const answer6 = params.get('answer6')
                const answer7 = params.get('answer7')

                const apiUrl = `${process.env.GATSBY_API_URL}/api/v1/results/?answer1=${answer1}&answer2=${answer2}&answer3=${answer3}&answer4=${answer4}&answer5=${answer5}&answer6=${answer6}&answer7=${answer7}`

                const response = await axios.get(apiUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                })
                console.log('API Response:', response.data)
                setHairConcern(response.data.hair_concern)
                setHairType(response.data.hair_type)
                setProducts(response.data.product_result)
            } catch (error) {
                console.log('Error fetching data:', error)
            }
        }
        fetchData()
    }, [location.search]) 

    return (
        <div className="quiz-result">
            <MetaTags
                title="Ellips | Result Hair Quiz"
                description={`${t('quiz.meta-desc')}`}
                image={banner_desktop}
                url={typeof window !== 'undefined' ? window.location.href : ''}
            />
            <Navigation activeLanguage={pageContext.lang} />
            <div className="quiz-result-container">
                <div className="box-heading">
                    <div className="container">
                        <div className="box-result mont-regular">
                            <div className="box-top">
                                <div className="title-page">Quiz Result</div>
                                <div className="subtitle">
                                    My Hair and Scalp Profile
                                </div>
                                <div className="row box-triger">
                                    <div className="col-12 col-md-6 box-item">
                                        <div className="category-name">
                                            Your Hair Type
                                        </div>
                                        <div className="category-value">
                                            {hairType}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 box-item">
                                        <div className="category-name">
                                            Your Hair Concern
                                        </div>
                                        <div className="category-value">
                                            {hairConcern}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-bottom">
                                <div className="heading">
                                    <span>Product</span> Recommendations
                                </div>
                                <div className="row box-product">
                                    {products.map((product, index) => (
                                        <div
                                            key={index}
                                            className="col-12 col-md-4"
                                        >
                                            <div className="box-card">
                                                <div className="box-img">
                                                    <img
                                                        src={product.image_thumbnail}
                                                        alt={`Ellips - ${product.name}`}
                                                        className="img-fluid"
                                                    />
                                                </div>
                                                <div className="box-title">
                                                    {product.name}
                                                </div>
                                                <div className="box-button">
                                                    <LocalizedLink
                                                        to={`${product.slug}`}
                                                        className="btn btn-outline-primary btn-outline-primary__outline-black box-link grow shrink mont-regular"
                                                        target="_self"
                                                    >
                                                        See Product
                                                    </LocalizedLink>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withTrans(HairQuizResult)